<template>

  <!-- skeleton -->
  <div v-if="!isSliderLoad" class="skeleton container-fluid">
    <div class="home-slider">

    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="promo-ads">

        </div>
      </div>
      <div class="col-lg-4">
        <div class="promo-ads">

        </div>
      </div>
      <div class="col-lg-4">
        <div class="promo-ads">

        </div>
      </div>
    </div>

    <!-- sales now  -->
    <div class="row skeletion-products">
      <!-- <h4 class="text-center slider-title-text">Sales Now</h4> -->
      <div class="product_section sale_now">
        <div class="slider-titile">
          <h4 class="text-center slider-title-text">SALES NOW</h4>
        </div>
      </div>
      <div class="col-md-12 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-md-12 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
    </div>


    <!-- sales now  -->
    <div class=" skeletion-products">
      <!-- <h4 class="text-center slider-title-text d-none d-cs-block">Currently Treanding</h4> -->
      <!-- <h4 class="text-center d-none d-sm-block bg-red">Currently Treanding</h4> -->
      <div class="product_section sale_now">
        <div class="slider-titile">
          <h4 class="text-center slider-title-text">CURRENTLY TRENDING</h4>
        </div>
      </div>
     <div class="row">
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6" >
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
      <div class="col-lg-2 col-cs-6">
        <div class="skeletion-card">
          <div class="skeletion-img"></div>
          <div class="skeletion-btn"></div>
          <div class="skeletion-title-one"></div>
          <div class="skeletion-title-two"></div>
          <div class="skeletion-price-one"></div>
          <div class="skeletion-price-two"></div>
          <div class="skeletion-add-btn"></div>
        </div>
      </div>
     </div>
    </div>

  </div>

  <!-- home data -->
  <div v-else class="home">

    <!-- <div v-if="!isSliderLoad" class="main_slider d-none d-md-block">
      <div style="width:100%; height:400px; background:red">

      </div>
    </div> -->
    <!-- <img v-if="!isSliderLoad" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height:80px" alt="logo"> -->
    <div v-if="isSliderLoad" class="main_slider d-none d-md-block">
      <Slider />
    </div>

    <div v-if="isSliderLoad" class="d-none d-md-block">
      <OfferArea />
    </div>
    <!--<OfferProduct/>-->
    <!-- <div v-if="isSliderLoad" class="">
      <SalesNow></SalesNow>
      <HomeDynamicSection></HomeDynamicSection>
      <LatestProduct/>
      <TopSellingProduct/>
    </div> -->

    <div v-if="isSliderLoad" class="section-dasktop-view">
      <br>
      <SalesNow></SalesNow>
      <br>
      <HomeDynamicSection></HomeDynamicSection>
      <br>
      <LatestProduct />
      <br>
      <TopSellingProduct />
    </div>

    <div v-if="isSliderLoad" class="section-mobile-view">
      <HomeDynamicSection></HomeDynamicSection>

      <LatestProduct />

      <SalesNow></SalesNow>

      <TopSellingProduct />
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import OfferArea from "../components/Home/OfferArea";
import SalesNow from "../components/Home/SalesNow";
import HomeDynamicSection from "../components/Home/HomeDynamicSection";
import LatestProduct from "../components/Home/LatestProduct";
import TopSellingProduct from "../components/Home/TopSellingProduct";
import Slider from "./header/Slider";
import { mapGetters } from "vuex";
import carousel from 'vue-owl-carousel'
export default {
  name: 'Home',
  data() {
    return {
      isSliderLoad: false
    };
  },
  metaInfo() {
    return {
      title: "Eurasia Supplies Brand Cosmetics of bangladesh",
      titleTemplate: "Eurasia Supplies Brand Cosmetics of bangladesh",
      meta: [
        /*{
            name: "description",
            content: this.productDetails.meta_description,
        },
        {
            name: "keyword",
            content: this.productDetails.meta_keyword,
        },*/
      ],
    };
  },
  async mounted() {
    // await this.$store.dispatch("getHome");
    // await this.$store.dispatch("getTopSelling");
    // await this.$store.dispatch("clearSiteUrl");
    // await this.$store.dispatch("getsaleNow");
    // console.log("Hello Home")
    setTimeout(function () {
      this.isSliderLoad = true
    }.bind(this), 500)
  },
  computed: {
    ...mapGetters(["loading", "home"]),
  },
  components: {
    OfferArea,
    SalesNow,
    HomeDynamicSection,
    LatestProduct,
    TopSellingProduct,
    Slider,
    carousel
  }
}
</script>


<style>
.section-mobile-view {
  display: none;
}

@media screen and (max-width: 576px) {
  .section-mobile-view {
    display: block;
  }

  .section-dasktop-view {
    display: none;
  }
}

/* home page skeleton */
.skeleton .home-slider {
  width: 93%;
  height: 400px;
  margin: auto;
  margin-top: 30px;
  border-radius: 4px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeleton .promo-ads {
  height: 200px;
  border-radius: 4px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  margin: 50px;
  animation: loading 1.5s infinite;
}

.skeletion-products {
  padding: 0px 50px;
}

.skeletion-card {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.skeletion-card .skeletion-img {
  width: 100%;
  height: 250px;
  border-radius: 4px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

}

.skeletion-card .skeletion-btn {
  width: 120px;
  height: 30px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  margin: auto;
  margin-top: 20px;
}

.skeletion-card .skeletion-title-one {
  width: 180px;
  height: 10px;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeletion-card .skeletion-title-two {
  width: 150px;
  height: 10px;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeletion-card .skeletion-price-one {
  width: 100px;
  height: 10px;
  margin-top: 25px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeletion-card .skeletion-price-two {
  width: 120px;
  height: 10px;
  margin-top: 10px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeletion-card .skeletion-add-btn {
  width: 100%;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@media screen and (max-width: 576px) {
  .skeleton .home-slider {
    display: none;
  }

  .skeleton .promo-ads {
    display: none;
  }

  .mobile-view-skeletion{
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
  }

  .skeletion-products{
    padding: 0px;
  }

  .col-cs-6{
    width: 50% !important;
    float: left;
  }

  .skeletion-card .skeletion-title-one{
    width: 135px;
  }

  .skeletion-card .skeletion-title-two{
    width: 135px;
  }

  .skeletion-card .skeletion-img{
    height: 170px;
  }
}
</style>